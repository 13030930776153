/**
 * This function calculates the value of a "vh" unit and sets it as a custom CSS variable (--vh) in the root of the document.
 *
 * @see {@link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/}
 */

export const initFullHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};
