import { initFullHeight } from '../util/full-height';

export const initHero = () => {
    const heroVideo = document.querySelector('.hero__video');

    if(!heroVideo) {
        return;
    }

    initFullHeight();
    fitHeroToContainer(heroVideo);
    watchResize(heroVideo);
};

/**
 * Adjusts the scale of a hero video to fit its container based on their respective aspect ratios.
 * Dynamically calculates the scale factor to ensure the video fills the container entirely,
 * with adjustments for specific screen sizes.
 *
 * @function fitHeroToContainer
 * @returns {void}
 */
const fitHeroToContainer = (heroVideo) => {
    let fullViewportOffset = 0;

    if (document.querySelector('.alert-bar')) {
        fullViewportOffset += 40;
    }

    let containerAspectRatio = parseFloat(
        getComputedStyle(heroVideo).getPropertyValue(
            '--container-aspect-ratio'
        ),
    );

    const videoAspectRatio = parseFloat(
        getComputedStyle(heroVideo).getPropertyValue('--video-aspect-ratio')
    );

    // Determine if the hero is full-viewport
    const isFullViewport =
        heroVideo.parentElement.classList.contains('hero--full');

    if (isFullViewport) {
        // Use the viewport's aspect ratio when 'hero--full' class is present
        // Offset by the notice bar as this affects proportion of hero in viewport
        containerAspectRatio =
            window.innerWidth / (window.innerHeight - fullViewportOffset);
    }

    // One mobile all the heros are 8:5 except the full hero
    if (window.matchMedia('(max-width: 1023px)').matches && !isFullViewport) {
        containerAspectRatio = 8 / 5;
    }

    // Calculate scale factor dynamically
    const scaleFactor =
        containerAspectRatio > videoAspectRatio
            ? containerAspectRatio / videoAspectRatio
            : videoAspectRatio / containerAspectRatio;

    // Set scale factor as a CSS variable
    heroVideo.style.setProperty(
        '--video-scale',
        roundToHighestFirstDecimal(scaleFactor),
    );
};

// Prevents a rounding error giving as thin white strips either side of the video
const roundToHighestFirstDecimal = num => {
    return Math.ceil(num * 10) / 10;
};

const watchResize = (element) => {
    const resizeObserver = new ResizeObserver(() => {
        fitHeroToContainer(element);
    });

    resizeObserver.observe(element);
};
